<template>
  <div class="set-container">
    <div class="left">
      <div
        :class="{ current: currentIndex === index }"
        @click="$router.push(`/set?type=${index}`)"
        v-for="(item, index) in navList"
        :key="index"
      >
        <img class="img1" :src="item.icon" />
        <img class="img2" :src="item.icon1" />
        {{ item.name }}
      </div>
    </div>
    <div class="right">
      <form-set-info v-if="currentIndex === 0"></form-set-info>
      <form-up-password v-if="currentIndex === 1"></form-up-password>
      <form-bind v-if="currentIndex === 2"></form-bind>
    </div>
  </div>
</template>
<script>
import formBind from "@/components/form/form-bind";
import formSetInfo from "@/components/form/form-set-info";
import formUpPassword from "@/components/form/form-up-password";
export default {
  components: {
    formBind,
    formSetInfo,
    formUpPassword,
  },

  data() {
    return {
      currentIndex: 0,
      navList: [
        {
          name: "基本设置",
          icon: require("../../src/assets/set/set.png"),
          icon1: require("../../src/assets/set/set1.png"),
        },
        {
          name: "修改密码",
          icon: require("../../src/assets/set/re.png"),
          icon1: require("../../src/assets/set/re1.png"),
        },
        {
          name: "账户绑定",
          icon: require("../../src/assets/set/bind.png"),
          icon1: require("../../src/assets/set/bind1.png"),
        },
        // {
        //   name:'消息中心',
        //   icon:require('../../src/assets/set/2.png')
        // }
      ],
    };
  },
  watch: {
    "$route.query.type": {
      immediate: true,
      handler(v) {
        v && (this.currentIndex = ~~v);
      },
    },
    "$store.state.user.userInfo": {
      handler(v) {
        !v && this.$router.push("/");
      },
    },
  },
};
</script>
<style scoped lang="scss">
.set-container {
  display: flex;
  margin-top: 68px;
  align-items: flex-start;
}
.left {
  width: 200px;
  margin-right: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  > div {
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 20px;
      margin: 0 30px;
    }
    .img1 {
      display: block;
    }
    .img2 {
      display: none;
    }
  }
  > div.current,
  > div:hover {
    background: #c20c0cff;
    //box-shadow: 0px 2px 8px 0px #D27C7C;
    color: #ffffff;
    .img1 {
      display: none;
    }
    .img2 {
      display: block;
    }
  }
}
.right {
  width: 0;
  flex-grow: 1;
  min-height: 500px;
}
</style>
