<template>
  <div>
    <el-form
      :model="infoForm"
      label-width="170px"
      label-position="left"
      style="width: 550px"
    >
      <el-form-item label="头像">
        <div class="avatar">
          <div class="image">
            <img
              v-if="this.infoForm.avatar.url"
              :src="this.infoForm.avatar.url"
            />
            <img v-else :src="avatar" />
          </div>
          <div>
            <div class="tip">
              格式：支持格式为JPG、PNG 尺寸：建议尺寸200*200 大小：不超过10M
            </div>
            <el-upload
              class="upload-demo"
              action="/api/oss/upload"
              :on-success="avatarSession"
              :show-file-list="false"
            >
              <div class="btn">更换头像</div>
            </el-upload>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          class="diy-input"
          v-model="infoForm.name"
          placeholder="请输入昵称"
          :maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" class="diy-radio">
        <el-radio v-model="infoForm.sex" label="0">靓女</el-radio>
        <el-radio v-model="infoForm.sex" label="1">靓仔</el-radio>
        <el-radio v-model="infoForm.sex" label="2">保密</el-radio>
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker
          style="width: 380px"
          class="diy-input"
          popper-class="diy-date-picker"
          v-model="infoForm.birthday"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="地区">
        <el-popover
          popper-class="address-popper"
          placement="bottom"
          width="380"
          trigger="click"
          ref="popover"
        >
          <com-region :name.sync="infoForm.address" @close="popoverClose"></com-region>
          <div class="address" slot="reference">
            <span v-if="infoForm.address.length > 0">{{
              infoForm.address
            }}</span>
            <span v-else style="color: #d6d6d6">请选择地区</span>
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item label="个人简介">
        <el-input
          :maxlength="500"
          class="diy-textarea"
          type="textarea"
          v-model="infoForm.introduction"
          placeholder="请输入个人简介"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <com-button @click="submit">保存</com-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import comRegion from "@/components/com/com-region";
import { validatenull } from "@/util/validate";
import { mapGetters } from "vuex";
export default {
  components: {
    comButton,
    comRegion,
  },
  data() {
    return {
      infoForm: {
        avatar: {
          url: "",
        },
        sex: "",
        birthday: "",
        address: "",
        introduction: "",
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      avatar: "avatar",
    }),
  },
  watch: {
    "$store.state.user.userInfo": {
      immediate: true,
      handler(userInfo) {
        if (userInfo === null) return;
        this.infoForm.avatar.url = userInfo.icon;
        this.infoForm.birthday =
          userInfo.birthday === "0001-01-01" ? "" : userInfo.birthday;
        this.infoForm.introduction = userInfo.intro;
        this.infoForm.name = userInfo.nickname;
        this.infoForm.sex = userInfo.sex + "";
        this.infoForm.address = userInfo.address;
      },
    },
  },
  methods: {
    popoverClose(){
      this.$refs.popover.doClose()
    },
    avatarSession(res) {
      this.infoForm.avatar.url = res.data.url;
    },
    submit() {
      if (
        validatenull(this.infoForm.name)
        // validatenull(this.infoForm.avatar.url)
      ) {
        this.$message.error("昵称和头像不能为空");
        return;
      }
      console.log(this.infoForm.birthday);
      let params = {
        id: this.$store.state.user.userInfo.id,
        icon: this.infoForm.avatar.url,
        nickname: this.infoForm.name,
        sex: this.infoForm.sex,
        birthday: this.infoForm.birthday,
        intro: this.infoForm.introduction,
        address: this.infoForm.address,
      };
      console.log(params);
      this.$api.sendSavaUserInfo(params).then((resolve) => {
        console.log(resolve);
        if (resolve.code === 200) {
          this.$message.success("保存成功");
          this.$store.dispatch("getUserInfo");
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "../../style/form";
.address-popper {
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.avatar {
  display: flex;
  .image {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 30px;
    overflow: hidden;
    border: 1px solid #d6d6d6;
    img {
      width: 100%;
    }
  }
  .tip {
    font-size: 16px;
    color: #a0a2a6;
    margin-right: -50px;
    margin-top: 10px;
    height: 100px;
    line-height: 25px;
  }
  .btn {
    height: 36px;
    line-height: 36px;
    color: #333333;
    font-size: 18px;
    padding: 0 14px;
    margin-top: 20px;
    border-radius: 2px;
    border: 1px solid #d6d6d6;
  }
}
.address {
  width: 380px;
  border: 1px solid #d6d6d6 !important;
  border-radius: 2px;
  cursor: pointer;
  padding-left: 15px;
  color: #606266; //#D6D6D6FF
  box-sizing: border-box;
}
</style>
