<template>
  <div class="bind-container">
    <div class="row">
      <div class="label">手机绑定</div>
      <div class="item">
        <div class="icon"><img src="../../assets/phone.png" /></div>
        <div class="t1" v-if="myPhone">{{ myPhone | hidePhone }}</div>
        <div class="t1" v-else>未绑定</div>
        <div class="t2">（可用于登录找回密码）</div>
        <div class="btn up-phone" @click="bindUpPhone">
          {{ myPhone ? "修改手机" : "绑定手机" }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="label">邮箱绑定</div>
      <div class="item">
        <div class="icon"><img src="../../assets/email.png" /></div>
        <div class="t1" v-if="myEmail">{{ myEmail | hideEmail }}</div>
        <div class="t1" v-else>未绑定</div>
        <div class="t2">（可用于登录找回密码）</div>
        <div
          class="btn del-bind"
          v-if="myEmail"
          @click="(oldEmailCode = ''), (codeTime = 0), (emailVisible = true)"
        >
          解除绑定
        </div>
        <div
          class="btn del-bind"
          v-else
          @click="
            (email = emailCode = ''), (codeTime = 0), (emailBindVisible = true)
          "
        >
          绑定
        </div>
      </div>
    </div>

    <com-mask :visible="phoneVisible">
      <div class="box">
        <div class="box-h">
          验证<i class="el-icon-close" @click="phoneVisible = false"></i>
        </div>
        <div class="box-t">使用 手机 {{ myPhone | hidePhone }} 进行验证</div>
        <div class="box-r">
          <span>验证码</span>
          <span
            ><el-input
              class="diy-input"
              v-model="oldPhoneCode"
              placeholder="请输入验证码"
            ></el-input
          ></span>
          <span v-if="codeTime" class="codeTime">{{ codeTime }}</span>
          <span @click="getOldPhoneCode" v-else>获取验证码</span>
        </div>
        <div class="box-btns">
          <com-button @click="next">下一步</com-button>
        </div>
      </div>
    </com-mask>

    <com-mask :visible="phoneBindVisible">
      <div class="box">
        <div class="box-h">
          手机绑定<i
            class="el-icon-close"
            @click="phoneBindVisible = false"
          ></i>
        </div>
        <div class="box-r">
          <span>手机号</span>
          <span
            ><el-input
              class="diy-input"
              v-model="newPhone"
              placeholder="请输入手机号"
            ></el-input
          ></span>
        </div>
        <div class="box-r">
          <span>验证码</span>
          <span
            ><el-input
              class="diy-input"
              v-model="newPhoneCode"
              placeholder="请输入验证码"
            ></el-input
          ></span>
          <span v-if="codeTime" class="codeTime">{{ codeTime }}</span>
          <span @click="getNewPhoneCode" v-else>获取验证码</span>
        </div>
        <div class="box-btns">
          <com-button @click="bindSubmit">提交</com-button>
        </div>
      </div>
    </com-mask>

    <com-mask :visible="emailVisible">
      <div class="box">
        <div class="box-h">
          提醒<i class="el-icon-close" @click="emailVisible = false"></i>
        </div>
        <div class="box-t">
          解绑后你将无法使用邮箱登录熙微DJ，你确定要解绑吗？
        </div>
        <div class="box-r">
          <span>验证码</span>
          <span
            ><el-input
              class="diy-input"
              v-model="oldEmailCode"
              placeholder="请输入验证码"
            ></el-input
          ></span>
          <span v-if="codeTime" class="codeTime">{{ codeTime }}</span>
          <span @click="getOldEmailCode" v-else>获取验证码</span>
        </div>
        <div class="box-btns">
          <com-button @click="undoEmailBind">提交</com-button>
          <com-button type="white" @click="emailVisible = false"
            >取消</com-button
          >
        </div>
      </div>
    </com-mask>

    <com-mask :visible="emailBindVisible">
      <div class="box">
        <div class="box-h">
          绑定邮箱<i
            class="el-icon-close"
            @click="emailBindVisible = false"
          ></i>
        </div>
        <div class="box-r">
          <span>邮箱</span>
          <span
            ><el-input
              class="diy-input"
              v-model="email"
              placeholder="请输入邮箱"
            ></el-input
          ></span>
        </div>
        <div class="box-r">
          <span>验证码</span>
          <span
            ><el-input
              class="diy-input"
              v-model="emailCode"
              placeholder="请输入验证码"
            ></el-input
          ></span>
          <span v-if="codeTime" class="codeTime">{{ codeTime }}</span>
          <span @click="getemailCode" v-else>获取验证码</span>
        </div>
        <div class="box-btns">
          <com-button @click="bindEmailSubmit">提交</com-button>
        </div>
      </div>
    </com-mask>
  </div>
</template>
<script>
import comMask from "@/components/com/com-mask";
import ComButton from "@/components/com/com-button";
import { isEmail, isMobile, validatenull } from "@/util/validate";
export default {
  components: {
    ComButton,
    comMask,
  },
  data() {
    return {
      phoneVisible: false,
      emailVisible: false,
      phoneBindVisible: false,
      emailBindVisible: false,
      oldPhoneCode: "",
      newPhone: "",
      newPhoneCode: "",
      codeTime: 0,
      email: "",
      emailCode: "",
      oldEmailCode: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    myPhone() {
      return this.userInfo.phone;
    },
    myEmail() {
      return this.userInfo.email.length > 0 ? this.userInfo.email : null;
    },
  },
  watch: {
    codeTime(v) {
      v > 0
        ? setTimeout(() => {
            v > 0 && this.codeTime--;
          }, 1000)
        : (this.codeTime = 0);
    },
  },
  methods: {
    bindUpPhone() {
      this.newPhoneCode = "";
      this.newPhone = "";
      this.oldPhoneCode = "";
      this.codeTime = 0;
      this.myPhone
        ? (this.phoneVisible = true)
        : (this.phoneBindVisible = true);
    },
    next() {
      if (validatenull(this.oldPhoneCode)) {
        this.$message.error("请输入验证码");
        return;
      }
      this.$api
        .checkCode({ account: this.myPhone, code: this.oldPhoneCode })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.phoneVisible = false;
            this.phoneBindVisible = true;
            this.codeTime = 0;
            this.newPhoneCode = "";
            this.newPhone = "";
          } else {
            this.$message.error("验证码错误");
          }
        });
    },
    getOldPhoneCode() {
      this.$api.sendPhoneCode({ phone: this.myPhone }).then((resolve) => {
        if (resolve.code === 200) {
          this.$message.success("发送成功");
          this.codeTime = 60;
        }
      });
    },
    getNewPhoneCode() {
      if (!isMobile(this.newPhone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      this.$api.sendPhoneCode({ phone: this.newPhone }).then((resolve) => {
        // console.log(resolve);
        if (resolve.code === 200) {
          this.$message.success("发送成功");
          this.codeTime = 60;
        }
      });
    },
    bindSubmit() {
      if (!isMobile(this.newPhone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (validatenull(this.newPhoneCode)) {
        this.$message.error("请输入验证码");
        return;
      }
      this.$api
        .sendBindPhone({
          ophone: this.myPhone,
          phone: this.newPhone,
          code: this.newPhoneCode,
          binding: true,
        })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.$store.dispatch("getUserInfo");
            this.phoneBindVisible = false;
          } else {
            if (resolve.msg === "操作失败") this.$message.error("验证码错误");
            else this.$message.error(resolve.msg);
          }
        });
    },
    getemailCode() {
      isEmail(this.email)
        ? this.$api.sendEmailCode({ email: this.email }).then((resolve) => {
            if (resolve.code === 200) {
              this.codeTime = 60;
              this.$message.success("发送成功");
            }
          })
        : this.$message.error("请输入正确的邮箱");
    },
    getOldEmailCode() {
      this.$api.sendEmailCode({ email: this.myEmail }).then((resolve) => {
        if (resolve.code === 200) {
          this.codeTime = 60;
          this.$message.success("发送成功");
        }
      });
    },
    bindEmailSubmit() {
      if (!isEmail(this.email)) {
        this.$message.error("请输入正确的邮箱");
        return;
      }
      if (validatenull(this.emailCode)) {
        this.$message.error("请输入验证码");
        return;
      }
      this.$api
        .sendBindEmail(true, {
          email: this.email,
          code: this.emailCode,
          // binding: true,
        })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.$store.dispatch("getUserInfo");
            this.emailBindVisible = false;
          } else {
            if (resolve.msg === "操作失败") this.$message.error("验证码错误");
            else this.$message.error(resolve.msg);
          }
        });
    },
    undoEmailBind() {
      if (!this.myPhone) {
        this.$message.error("未绑定手机号，无法解除绑定邮箱");
        return;
      }
      if (this.oldEmailCode.length === 0) {
        this.$message.error("请输入验证码");
        return;
      }
      this.$api
        .sendBindEmail(false, {
          email: this.myEmail,
          code: this.oldEmailCode,
          // binding: false,
        })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.codeTime = 0;
            this.$store.dispatch("getUserInfo");
            this.emailVisible = false;
          }
        });
    },
  },
  filters: {
    hidePhone(v) {
      v = v + "";
      // console.log(v)
      let res = v.split("").map((item, index) => {
        return index > 2 && index < 7 ? "*" : item;
      });
      return res.join("");
    },
    hideEmail(v) {
      v = v + "";
      let first = v.split("@")[0];
      let last = v.split("@")[1];
      let res = first.split("").map((item, index) => {
        return index > 1 && index < first.length - 2 ? "*" : item;
      });
      return res.join("") + "@" + last;
    },
  },
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  font-size: 18px;
  .item {
    display: flex;
    align-items: center;
  }
  .label {
    width: 170px;
    text-align: left;
    color: #a0a2a6;
  }
  .icon {
    width: 50px;
    height: 50px;
    margin-right: 30px;
    img {
      width: 100%;
    }
  }
  .t1 {
    width: 200px;
    color: #333333;
  }
  .t2 {
    width: 230px;
    color: #a0a2a6;
  }
  .btn {
    width: 100px;
    height: 36px;
    line-height: 36px;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
  }
  .up-phone {
    background: #c20c0cff;
    color: #ffffff;
  }
  .del-bind {
    border: 1px solid #a0a2a6;
    color: #696b6f;
  }
}
.box {
  width: 448px;
  background: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.27);
  border-radius: 2px;
  padding: 0 40px;
  box-sizing: border-box;
  .box-h {
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #333333;
    font-size: 18px;
    border-bottom: 1px solid #eee;
    position: relative;
    i {
      position: absolute;
      right: -20px;
      top: 20px;
    }
  }
  .box-t {
    font-size: 14px;
    color: #a0a2a6;
    margin-top: 30px;
  }
  .box-r {
    margin-top: 20px;
    display: flex;
    > span:nth-child(1) {
      width: 65px;
      line-height: 40px;
    }
    > span:nth-child(2) {
      flex-grow: 1;
    }
    > span:nth-child(3) {
      margin-left: 10px;
      width: 100px;
      height: 40px;
      line-height: 40px;
      border-radius: 2px;
      border: 1px solid #c20c0c;
      box-sizing: border-box;
      text-align: center;
      color: #c20c0c;
      font-size: 14px;
      cursor: pointer;
    }
    //.codeTime{
    //  background: #000;
    //  border: 0;
    //}
  }
  .box-btns {
    display: flex;
    justify-content: center;
    > * {
      margin: 50px 10px 30px;
    }
  }
}
</style>
