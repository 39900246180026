<template>
  <div>
    <el-form
      :model="singleForm"
      label-width="170px"
      label-position="left"
      style="width: 450px"
    >
      <el-form-item label="当前密码" style="position: relative">
        <el-input
          class="diy-input"
          v-model="singleForm.password"
          @blur="check"
          show-password
          placeholder="请输入密码"
        ></el-input>
        <!--        <span v-if="passwordError === false" class="success-info"-->
        <!--          ><span class="icon"><img src="../../assets/ok.png" /></span-->
        <!--          >密码正确</span-->
        <!--        >-->
        <!--        <span v-if="passwordError === true" class="error-info"-->
        <!--          ><span class="icon"><img src="../../assets/err.png" /></span-->
        <!--          >密码错误</span-->
        <!--        >-->
      </el-form-item>
      <el-form-item label="新密码">
        <el-input
          class="diy-input"
          v-model="singleForm.newPassword"
          show-password
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认新密码">
        <el-input
          class="diy-input"
          v-model="singleForm.confirmPassword"
          show-password
          placeholder="再次输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <com-button @click="submit">保存</com-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import { validatePassword } from "@/util/validate";
export default {
  components: {
    // comInput,
    comButton,
  },
  data() {
    return {
      singleForm: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      passwordError: true,
    };
  },

  methods: {
    check() {
      if (this.singleForm.password !== "") {
        this.passwordError = false;
      } else {
        this.passwordError = true;
      }
    },
    submit() {
      let { confirmPassword, newPassword, password } = this.singleForm;
      if (this.passwordError) {
        this.$message.error("密码错误");
      } else if (!validatePassword(newPassword)) {
        //!this.checkPassword(newPassword)
        this.$message.error("密码为6-16位，需组合数字、字母和符号");
      } else if (confirmPassword !== newPassword) {
        this.$message.error("2次密码输入不一致");
      } else if (password === newPassword) {
        this.$message.error("新密码与旧密码相同");
      } else {
        this.sendUpPassword();
      }
    },
    sendUpPassword() {
      let { password, newPassword } = this.singleForm;
      let params = {
        oldPwd: password,
        newPwd: newPassword,
      };
      this.$api.sendUpPassword(params).then((resolve) => {
        // console.log(resolve);
        if (resolve.code === 200) {
          this.$message.success("修改成功");
          Object.assign(this.$data, this.$options.data()); //重置页面数据
        } else {
          if (resolve.msg === "操作失败") resolve.msg = "密码错误";
          this.$message.error(resolve.msg);
        }
      });
    },
    checkPassword(v) {
      return v.length >= 6 && v.length <= 16;
    },
  },
};
</script>
<style lang="scss">
@import "../../style/form";
</style>
<style lang="scss" scoped>
.icon {
  img {
    width: 100%;
  }
}
</style>
